












import { Component, Vue } from 'vue-property-decorator';
import InvitationCard from '@/components/cards/InvitationCard.vue';

@Component({
  components: { InvitationCard },
})
export default class VerifyInvitation extends Vue {
}
